<template>
  <secondary-page-layout :title="$t('confirmation')">
    <v-dialog/>
    <div class="confirm-page form-group">
      <otp-verify v-model="code" :info="confirmInfo" @submit="verifyCode" @sendCode="sendConfirmCode" :error-message="errorMessage"></otp-verify>

      <form-confirm-info :termsAgree="i_agree" :info="confirmInfo" @changeTermsAgree="changeTermsAgree"  :insurance_type="insuranceType"/>
    </div>

    <button @click="generateOtp" class="primary-btn bottom-page-btn" :disabled="!i_agree"> {{ $t('confirm') }} </button>
  </secondary-page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormConfirmInfo from "../components/FormConfirmInfo";
import CreditCardSelect from "/src/components/BuyPolicy/CreditCardSelect";
import eventLogger from "../services/eventLogger";
import OtpVerify from "../components/OTP/OtpVerify";

export default {
  name: "OgpoReinsuranceConfirm",
  components: {FormConfirmInfo, CreditCardSelect, OtpVerify},
  computed:{
    ...mapGetters({
      calculatedSum: "reinsuranceForm/calculatedSum",
      startDate: "reinsuranceForm/beginDate",
      phoneNumber: "reinsuranceForm/phoneNumber",
      selectedProductId: "reinsuranceForm/selectedProductId",
      products: "reinsuranceForm/products",
      clients: "reinsuranceForm/clients",
      otpKey: 'reinsuranceForm/otpKey',
      friendBasket: 'reinsuranceForm/friendBasket',
      invoiceId: 'reinsuranceForm/invoiceId',
      homebankToken: 'reinsuranceForm/homebankToken',
      email: 'reinsuranceForm/email',
      selectedProductName: "reinsuranceForm/selectedProductName",
      period: 'reinsuranceForm/period',
      beginDate: "reinsuranceForm/beginDate",
      cascoSelected: 'reinsuranceForm/cascoSelected'
    }),
    fixSum() {
      return this.calculatedSum?.fixedSum;
    },
    bonus() {
      const { selectedProductId, products } = this;

      if(!selectedProductId || products.length === 0) { return }

      return products.find((b) => b.mergeProduct.id === selectedProductId)
    },
    fullName() {
      if(!this.clients || this.clients.length === 0) { return }

      const mainClient = this.clients.find(c => c.mainClient);

      return mainClient?.fullName || this.clients[0].fullName;
    },
    confirmInfo() {
      return {
        fullname: this.fullName,
        phone_number: this.phoneNumber,
        start_date: this.startDate,
        bonus_product: this.bonus,
        amount: this.fixSum
      }
    }
  },
  data() {
    return {
      i_agree: true,
      insuranceType: 1000,
      card: null,
      errorMessage: null,
      code: '',
      calculationData: null
    }
  },
  async mounted() {
    if(this.allVerified === 0) {
      this.$modal.show('dialog', {
        title: 'Уважаемый клиент',
        text: this.$i18n.t('allVerifiedDisabled'),
        buttons: [{
          title: 'OK',
          handler: () => {
            this.$modal.hide('dialog')
            return null;
          }
        }]
      }, {class: 'error'});
    }

    await this.generateOtp();
  },
  watch: {
    otpKey() {
      this.saveDraft()
    }
  },
  methods:{
    ...mapActions({
      sendOTP: 'reinsuranceForm/sendOTP',
      saveDraft: 'reinsuranceForm/saveDraft'
    }),
    async generateOtp () {
      const loader = this.$loading.show({});

      try {
        const loggerPayload = {
          benefit_product: this.selectedProductName,
          period: this.period,
          kasko: this.cascoSelected,
          product_type: 'ogpo',
          payment_sum: this.calculatedSum.fixedSum,
          iin: this.clients.map(client => client.iin),
          phone_number: this.phoneNumber
        }

        await eventLogger('confirm_payment', loggerPayload)

        await this.sendOTP()
        loader.hide()
      } catch (e) {
        this.$modal.show('dialog', {
          title: 'Произошла ошибка',
          text: this.$i18n.t('allVerifiedDisabled'),
          buttons: [{
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
              return null;
            }
          }]
        }, {class: 'error'});

        return loader.hide();
      }

      this.$router.push({
        name:'ogpo-reinsurance.otp.verify',
      })
    },
    async sendConfirmCode() {
      try {
        await this.sendOTP();

        this.showVerify = true;
      } catch (e) {
        throw e
      }
    },

    async verifyCode(code) {
      let loader = this.$loading.show();

      try {
        await this.verifyOTP(code)

        const { data } = await window.axios.post(`insurance-products/calculations/ogpo`, {
          invoiceId: this.invoiceId.toString(),
          amount: this.fixSum,
          holder: this.clients[0],
          phone_number: this.phoneNumber,
          beginDate: this.beginDate,
          calculation: this.$store.state['ogpoForm'].draftId
        })

        this.calculationData = data;

        await this.saveDraft();

        await this.goToPaymentPage(this.calculationData, this.$store.getters['authUser'])

      } catch (err) {
        console.log(err)
        if (err.code === 410) {
          this.errorMessage = "Пользователь ввел неверный код подтверждения";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.tryAmount");

        }
        if (err.code === 404) {
          this.errorMessage =  "Время действия кода подтверждения истекло";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.timeGone");

        }
        if (err.code === 400) {
          this.errorMessage = "Пользователь ввел неверный код подтверждения";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.invalidCode");

        }
        if (err.code === 403) {
          this.errorMessage = "Код более не валиден";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.codeGone");
        }
        if (err.code === 500) {
          this.errorMessage = "Не удалось верифицировать номер телефона";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.serviceError");
        }
      } finally {
        loader.hide()
      }
    },
  }
};
</script>
