<template>
  <div class="confirm-page form-group">
    <section>
      <span class="final-price" v-if="info && info.amount">{{ info.amount | priceFormat }} ₸</span>
    </section>

    <p class="text-black px-3 text-center sms-confirm-text">
      {{$t('smsConfirm')}} <br> <span v-if="info" class="sms-confirm-text">{{info.phone_number | phoneMasked}}</span>
    </p>
    <div class="primary-block sms-block">
      <div class="flex">
        <the-mask
            :mask="['# # # #']"
            type="text"
            inputmode="numeric"
            pattern="[0-9]*"
            autocomplete="off"
            name="code"
            id="single-factor-code-text-field"
            placeholder="— — — —"
            v-model="otp"
            @input="otpVerify"
        />
      </div>

    </div>
    <div style="text-align: center">
      <span class="text-danger">{{ errorMessage }}</span>
    </div>
    <div class="get-sms-code">
      {{$t('nextCode')}}
      <span v-show="timer > 0" class="success-text">{{ timer }} {{$t('nextCodeSec')}}</span>
      <span
        @click="$emit('sendCode')"
        v-show="timer <= 0"
        class="success-text send-code-btn"
      >
        {{$t('sendCodeAgain')}}
      </span>
    </div>
    <div class="selected-bonus primary-block" v-if="bonus">
      <h3 class="title">Вы выбрали Бонус:</h3>
      <p class="success-text">{{bonus.name}}</p>
    </div>
  </div>
</template>

<script>

import InputField from "../BuyPolicy/InputField";
export default {
  name: "SmsCodeConfirm",
  props: {
    value: {
      type: String,
    },
    errorMessage: {
      type: String,
    },
    info: null,
    bonus: null
  },
  components: {
    InputField
  },
  data() {
    return {
      otp: '',
      hiddenCode: '',
      timer: 59,
    };
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    otpVerify(){
      if (this.otp.length === 4) {
        this.handleOnComplete(this.otp)
      }
    },
    handleOnComplete(value) {
      this.$emit(
          "submit",
          value
      );
    },
    startTimer() {
      this.timer = 300;
      let interval = setInterval(() => {
        if (this.timer == 1) {
          clearInterval(interval);
        }
        this.timer = this.timer - 1;
      }, 1000);
    },
  },
  watch:{
    hiddenCode(newValue){
      this.$refs.otpInput.otp = newValue.split('');
    }
  }
};
</script>

<style lang="scss">
.sms-confirm-text {
  font-size: 14px;
  font-weight: bold;
}
.single-input-otp{
  border-bottom: 3px dashed #E0E6EF;
  border-radius: 0 !important;
  font-weight: 800 !important;
  font-size: 30px !important;
  min-width: 24px;
  text-align: center;
  color: #071222 !important;
  margin: 0 10px;
}
.sms-block {
  max-width: 215px;
  margin: 0 auto;
  padding: 12px 24px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 12px !important;
  .input {
    font-weight: 800;
    font-size: 34px;
    width: 100%;
    &.pin-item {
      border-radius: unset;
      padding-bottom: 1px;
      border-bottom: 3px solid #e0e6ef;
      width: 25px;
      height: 40px;
    }
  }
}
.get-sms-code {
  font-weight: 500;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #99a3b3;
  margin: 16px 0;
}
.send-code-btn {
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  cursor: pointer;
}
.selected-bonus {
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
  .title {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
  }
}
.code-input {
  border-bottom: 2px solid #e0e6ef;
  margin: 0 3px;
  font-size: 24px;
  text-align: center;
  width: 25px;
}

.otp-input {
  border-bottom: 3px solid #E0E6EF;
  border-radius: 0 !important;
  font-weight: 800 !important;
  font-size: 30px !important;
  min-width: 24px;
  text-align: center;
  color: #071222 !important;
  margin: 0 10px;
}

#single-factor-code-text-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #071222 !important;;
  background-color: transparent;
  -webkit-box-shadow: none;
  font-size: 2rem;
  letter-spacing: 0.4rem;
  text-align: center;
  display: block;
  font-weight: bold;

  &::placeholder {
    color: #b2bac6;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.4rem;
    text-align: center;
  }

  &:-ms-input-placeholder {
    color: #b2bac6;
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: #b2bac6;
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }
}
</style>
